class ResponseHandler {
    static handleResponse(response) {
        if (!response.ok) {
            return Promise.reject(response.json());
        }
        return response.json();
    }
}

export default ResponseHandler;
