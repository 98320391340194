import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";

const StyleSheet = {
    container: {
        // border: "0px solid rgba(255, 255, 255, 1)",
        // boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
        // borderRadius: "4px",
        // backgroundColor: "white",
        display: "flex",
        padding: "16px",
        width: "100%"
    },
    circle: {
        height: "40px",
        width: "40px",
        borderRadius: "50%"
    },
    lineLong: {
        width: "90%",
        height: "8px",
        borderRadius: "8%"
    },
    lineMedium: {
        width: "40%",
        height: "25px"
    },
    line: {
        width: "60px",
        height: "8px",
        borderRadius: "8px"
    }
};
class CustomShimmer extends Component {
    render() {
        const { classes } = this.props;
        return <Shimmer><div className={classes.lineMedium} /></Shimmer>;
    }
}

export default injectSheet(StyleSheet)(CustomShimmer);
