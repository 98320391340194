import { UrlConst } from "../constants";
import ResponseHandler from "./ResponseHandler";

const fetchPaymentData = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return fetch(UrlConst.FETCH_PAYMENT_DATA.replace(':id', id), requestOptions).then(ResponseHandler.handleResponse);
};

const generatePaymentLink = (id, name, email, currency) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({ name, email, currency })
    };
    return fetch(UrlConst.GENERATE_PAYMENT_LINK.replace(':id', id), requestOptions).then(ResponseHandler.handleResponse);
};

export const HomeService = {
    fetchPaymentData,
    generatePaymentLink
};
