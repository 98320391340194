import { combineReducers } from "redux";
import {
    PaymentDataReducer,
    PaymentLinkReducer
} from './auth';

const rootReducer = combineReducers({
    paymentData: PaymentDataReducer,
    paymentLink: PaymentLinkReducer
});

export default rootReducer;
