import './App.css';
import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import LayoutResolver from "./components/layout/LayoutResolver";
import AuthLayout from "./components/layout/AuthLayout";
import {HomeRouter} from "./HomeRouter";

import Home from "./components/home/Home";
import {connect} from "react-redux";
import NotFoundLayout from "./components/layout/NotFoundLayout";
import NotFound from "./components/NotFound";

function App(props) {
    return (
        <Router>
            <React.Suspense fallback={<>Hi</>}>
                <Switch>
                    <HomeRouter exact layout={AuthLayout} path="/pay/:id" component={Home}/>
                    <LayoutResolver layout={NotFoundLayout} component={NotFound}/>
                </Switch>
            </React.Suspense>
        </Router>
    );
}

export default connect(null, null)(App);
