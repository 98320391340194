import React from 'react';
import { AppConst } from "../../constants";

const Footer = () => {
    return (
        <footer className="pb-4 text-center font-size-14">
            <div className="container">
                {AppConst.APP_NAME} © {AppConst.APP_YEAR}.
                {` `}<a href={'https://www.payswitch.africa/privacy'} className={`text-dark color-accent-hover`}>Privacy Policy</a> |
                {` `}<a href={'https://www.payswitch.africa/terms'} className={`text-dark color-accent-hover`}>Terms and Conditions</a>
            </div>
        </footer>
    );
}

export default Footer;
