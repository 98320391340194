import 'dotenv/config';

class UrlBaseConst {
    static BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
    static API_URL =  `${UrlBaseConst.BASE_URL}/v1`;
}

export const UrlConst = {
    BASE_URL: UrlBaseConst.BASE_URL,
    FETCH_PAYMENT_DATA: `${UrlBaseConst.API_URL}/payment-link/:id`,
    GENERATE_PAYMENT_LINK: `${UrlBaseConst.API_URL}/payment-link/:id/generate`,
};
