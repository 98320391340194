import React, {Component} from 'react';
import { Button, Card, Col, Form, Row, Spinner, InputGroup, FormControl, Alert } from 'react-bootstrap';
import logo from '../../assets/images/payswitch.png';
import Validator from '../../helpers/validator';
import {HomeAction} from '../../actions';
import {connect} from 'react-redux';
import CustomShimmer from "../layout/CustomShimmer";
import Utility from "../../helpers/Utility";
import NotFound from "../NotFound";
import { Player } from "@lottiefiles/react-lottie-player";
import successful from "../../assets/raw/successful.json";
import failed from "../../assets/raw/payment-failed.json";

class Home extends Component {

    state = {
        error: {
            firstname: '',
            lastname: '',
            email: ''
        }
    };

    componentDidMount() {
        const { dispatch, match: { params: { id } } } = this.props;
        dispatch(HomeAction.fetchPaymentData(id));
    }

    submit = (e) => {
        e.preventDefault();

        const validator = new Validator(e);
        validator.validate('firstname').isUsername("Please enter a valid first name");
        validator.validate('lastname').isUsername("Please enter a valid last name");
        validator.validate('email').isEmail("Please enter a valid email address");

        if (validator.hasError()) {
            this.setState({ error: { ...validator.getErrorsFlat() } });
        } else {
            this.setState({ error: { firstname: '', lastname: '', email: '' }});
            const { dispatch, match: { params: { id } }, paymentData } = this.props;
            const firstname = validator.getValue('firstname');
            const lastname = validator.getValue('lastname');
            const email = validator.getValue('email');
            const name = `${firstname} ${lastname}`;
            const payment = paymentData?.data?.data?.payment;
            dispatch(HomeAction.generatePaymentLink(id, name, email, payment?.currency));
        }

    };

    render() {

        const { error} = this.state;
        const { paymentData, paymentLink, location } = this.props;

        const paymentLinkData = paymentLink?.data;

        const payment = paymentData?.data?.data?.payment;

        if (paymentData?.data?.status === false) {
            return <NotFound message={paymentData?.data?.message}/>;
        }

        if (paymentLinkData?.status) {
            window.location.href = paymentLinkData?.data?.authorization_url;
        }

        const query = new URLSearchParams(location.search);
        const status = query.get('status');

        return (
            <>
                {status ? <><Player
                    autoplay={true}
                    loop={true}
                    src={status === 'successful' ? successful : failed}
                    style={{ height: '150px', width: '150px' }}
                /><h4 className={'text-center mt-3'}>Payment {status}</h4></> : <>
                    <div className="mt-5 mb-4 text-center">
                        <img src={logo} alt="Logo" height={65} className={'mt-3 mb-1'}/><br/>
                        <p className="font-size-20 mb-0">Make payment to {paymentData.requesting ?
                            <Spinner animation="grow" variant="light"/> : <span
                                className={'font-weight-bold'}>{payment?.company?.name || payment?.user?.name}</span>}</p>
                    </div>

                    <Card border="light" className={`border-radius-30 mt-5`}>
                        <Card.Body className={`p-5`}>
                            <div className={'w-100 text-center'}>
                                {paymentData.requesting ? <div><CustomShimmer/></div> :
                                    <p className="font-size-20 font-weight-bold mb-0">{payment?.title}</p>}
                                {paymentData.requesting ? <div><CustomShimmer/></div> :
                                    <p><small className={'font-size-16'}>{payment?.description}</small></p>}
                            </div>

                            {paymentLinkData?.message && <Alert key={1} variant={paymentLinkData?.status ? `success` : `danger`}>{paymentLinkData?.message}</Alert>}

                            <Form onSubmit={this.submit}>
                                <Row className={'mt-3'}>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Form.Label
                                            className={`text-dark font-size-16 font-weight-bold`}>Amount</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="basic-addon1">{paymentData.requesting ?
                                                    <Spinner animation="grow"
                                                             variant="light"/> : payment?.currency}</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                placeholder="Enter amount"
                                                aria-label="Amount"
                                                aria-describedby="basic-addon1"
                                                value={Utility.numberFormat(payment?.amount || 0)}
                                                readOnly={true}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row className={'mt-3'}>
                                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Form.Group controlId="firstname">
                                            <Form.Label className={`text-dark font-size-16 font-weight-bold`}>First
                                                name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter first name" name="firstname"
                                                          isInvalid={!!error.firstname}/>
                                            <Form.Control.Feedback
                                                type="invalid">{error.firstname}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Form.Group controlId="lastname">
                                            <Form.Label className={`text-dark font-size-16 font-weight-bold`}>Last
                                                name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter last name" name="lastname"
                                                          isInvalid={!!error.lastname}/>
                                            <Form.Control.Feedback
                                                type="invalid">{error.lastname}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className={'mt-3'} controlId="email">
                                    <Form.Label className={`text-dark font-size-16 font-weight-bold`}>Email
                                        address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" name="email"
                                                  isInvalid={!!error.email}/>
                                    <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
                                </Form.Group>


                                <Button variant="primary" type="submit" size="lg"
                                        className={`font-size-16 min-height-55 w-100 text-uppercase mt-3`}>
                                    {paymentLink.requesting ? <Spinner animation="border" variant="light"/> : 'Pay'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </>}
            </>
        );
    }
}

export default connect(function (state) {
    return {
        paymentData: state.paymentData,
        paymentLink: state.paymentLink,
        loginAuth: state.loginAuth,
        loginWithGoogleAuth: state.loginWithGoogleAuth
    }
})(Home);
