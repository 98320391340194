import { HomeConst } from '../constants';

const PaymentDataReducer = (state = { requesting: false, data: {} }, action) => {
    switch (action.type) {
        case HomeConst.FETCH_PAYMENT_DATA_REQUEST:
            return { ...state, requesting: true };
        case HomeConst.FETCH_PAYMENT_DATA_SUCCESS:
            return { ...state, requesting: false, data: action.payload };
        case HomeConst.FETCH_PAYMENT_DATA_FAILURE:
            return { ...state, requesting: false, data: action.payload };
        default:
            return state;
    }
};

const PaymentLinkReducer = (state = { requesting: false, data: {} }, action) => {
    switch (action.type) {
        case HomeConst.GENERATE_PAYMENT_LINK_REQUEST:
            return { ...state, requesting: true };
        case HomeConst.GENERATE_PAYMENT_LINK_SUCCESS:
            return { ...state, requesting: false, data: action.payload };
        case HomeConst.GENERATE_PAYMENT_LINK_FAILURE:
            return { ...state, requesting: false, data: action.payload };
        default:
            return state;
    }
};

export {
    PaymentDataReducer,
    PaymentLinkReducer
};
